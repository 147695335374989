
    @import "config.scss";
 
.container {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  scroll-snap-align: start;
  gap: 1rem;
  white-space: nowrap;

  @include for-desktop-up {
    width: 48vw;
  }

  @include for-desktop-up {
    width: fit-content;
  }
}

.badge {
  background-color: var(--color-primary);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px;

  @include for-desktop-up {
    width: 60px;
    height: 60px;
  }
}
